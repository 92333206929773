.header {
    display: flex;
    justify-content: space-between;
}

.content {
    text-align: left;
    margin-top: 5vh;
}

.header-word {
    display: flex;
    align-items: center;
}

.nouns-selector-container {
    width: 90vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2vw;
    row-gap: 2vw;
}

.short-answer-selector-container {
    display: flex;
    max-height: 50vh;
    overflow: scroll;
    flex-direction: column;
    align-items: center;
    width: 70vw;
}

.short-answer-btn {
    display: block;
    margin-bottom: 2vw;
}

@media only screen
and (min-device-width: 768px)
and (max-device-width: 1024px) {
    .nouns-selector-container {
        width: 70vw;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 2vw;
        row-gap: 2vw;
    }

    .short-answer-selector-container {
        width: 50vw;
    }
}

.block {
    width: 32px;
}

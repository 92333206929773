.home {
    height: calc(100vh - 4vw);
    padding: 2vw 4vw;
}

.button-container {
    padding-top: 30vh;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 6vw;
    grid-row-gap: 6vw;
}

@media only screen
and (min-device-width: 768px)
and (max-device-width: 1024px) {
    .button-container {
        padding-top: 30vh;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 6vw;
        grid-row-gap: 6vw;
    }
}


@import '~antd/dist/antd.css';

.App {
  text-align: center;
  height: 100vh;
}

.mr-1 {
  margin-right: 1vw;
}

.mr-2 {
  margin-right: 2vw;
}

.mt-2 {
  margin-top: 2vw;
}

.mt-5 {
  margin-top: 5vw;
}

.ml-2 {
  margin-left: 2vw;
}

.mb-0 {
  margin-bottom: 0;
}
